import React, { useMemo, useState } from 'react'
import css from './Requests.module.css'
import { Box, Button, FormControl, InputLabel, ListSubheader, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { type Request, RequestType } from '../../types'
import columns from './Columns'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import Dialog from '../../components/dialog/Dialog'
import { useRequests } from '../../api/request'
import { useSearchParams } from 'react-router-dom'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import JSONView from '../../components/jsonView/JSONView'

const LIMIT_DEFAULT = 5

// creating a list of RequestTypeGroups
const RequestTypeGroups: Record<string, { label: string; options: RequestType[] }> = {
  post: {
    label: 'Post Generation',
    options: [RequestType.IDEA, RequestType.FRAME, RequestType.POST, RequestType.OPTIMIZATION, RequestType.FAQ, RequestType.TAKEAWAYS]
  },
  postV2: {
    label: 'Post Generation V2',
    options: [RequestType.POST_V2_SUBTITLES, RequestType.POST_V2_NOVELTY, RequestType.POST_V2_OUTLINES, RequestType.POST_V2_CONTENT]
  },
  post_improvements: {
    label: 'Post Improvement',
    options: [RequestType.POST_REVIEWING, RequestType.POST_UPDATING, RequestType.POST_TRANSLATION, RequestType.POST_IMPROVEMENT]
  },
  page_improvement: {
    label: 'Page Improvement',
    options: [
      RequestType.PAGE_PURPOSE,
      RequestType.PAGE_DESCRIPTION_MISALIGNMENTS,
      RequestType.PAGE_INTENTS,
      RequestType.PAGE_AUDIENCE,
      RequestType.PAGE_META_SUGGESTION,
      RequestType.PAGE_GAPS,
      RequestType.PAGE_GAPS_SUGGESTIONS,
      RequestType.PAGE_FINAL_SUGGESTIONS,
      RequestType.PAGE_USE_CONTENT_SUGGESTION,
      RequestType.PAGE_USE_META_SUGGESTION
    ]
  },
  keyword: {
    label: 'Keyword Generation',
    options: [RequestType.KEYWORDS]
  },
  editor: {
    label: 'Editor',
    options: [
      RequestType.TRANSLATION,
      RequestType.SUMMARY,
      RequestType.GRAMMAR,
      RequestType.IMPROVEMENT,
      RequestType.EXTENSION,
      RequestType.REWRITING,
      RequestType.REPHRASING,
      RequestType.SHORTENING,
      RequestType.SIMPLIFYING,
      RequestType.PARAPHRASING,
      RequestType.PERSONALIZING,
      RequestType.TONE_CHANGING,
      RequestType.STYLIZING
    ]
  },
  other: {
    label: 'Other',
    options: [RequestType.IMAGE_PROMPT, RequestType.IMAGE_ALT, RequestType.POST_THUMBNAIL_PROMPT]
  },
  unused: {
    label: 'Unused',
    options: [RequestType.KEYWORDS_STATS, RequestType.IMAGE]
  }
}
const GroupedRequestTypesList = Object.values(RequestTypeGroups).flatMap((group) => group.options)

// adding all RequestTypes that are not in RequestTypeGroups to the 'other' group
RequestTypeGroups.other.options.push(...Object.values(RequestType).filter((type) => !GroupedRequestTypesList.includes(type)))

interface TableProps {
  data: Array<Request<any>>
  setToView: (id: number) => void
}

interface SearchProps {
  type: RequestType | ''
  company_name: string | null
  company_id: number | string | null
  request_id: number | string | null
  blog_id: number | string | null
}

const SEARCH_EMPTY_VALUES: SearchProps = {
  type: '',
  company_name: '',
  company_id: null,
  request_id: null,
  blog_id: null
}

const TableRequests: React.FC<TableProps> = ({ data, setToView }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      view: (id: number) => {
        setToView(id)
      }
    }
  })

  return (
    <Table sx={{ maxWidth: '100%' }}>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell) => {
              const isExpandable = ['request', 'content'].includes(((cell.column?.columnDef?.header as string) || '').toLowerCase())
              const columnMaxWidth = isExpandable ? '1px' : undefined
              const columnWidth = isExpandable ? '25%' : undefined

              return (
                <TableCell sx={{ maxWidth: columnMaxWidth, width: columnWidth }} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Requests: React.FC = (props) => {
  const [toView, setToView] = useState<Request<any> | undefined>()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState<number>(LIMIT_DEFAULT)
  const [clearedFormKey, setClearedForm] = useState<number>(0)
  const [searchParams] = useSearchParams()
  const searchInitialValues = useMemo(() => {
    return {
      ...SEARCH_EMPTY_VALUES,
      company_id: searchParams.get('company_id')
    }
  }, [])
  const [search, setSearch] = useState<SearchProps>({
    ...searchInitialValues
  })
  const {
    data: { data, total } = { data: [] as Array<Request<any>>, total: 0 },
    isLoading,
    error
  } = useRequests({
    limit,
    offset: (page - 1) * limit,
    ...Object.entries(search).reduce((acc, [key, value]) => {
      if (value) {
        const valueType = typeof value === 'string' ? (value = value.trim()) : value
        if (valueType === 'string' && value.length >= 1) {
          return { ...acc, [key]: value }
        }
        return { ...acc, [key]: value }
      }
      return acc
    }, {})
  })

  const setDefaultSearch = (): void => {
    setSearch({ ...searchInitialValues })
    setClearedForm(Math.random())
  }
  const setSearchParam = (param: string, value: string | number | null): void => {
    setSearch({ ...search, [param]: value })
  }

  return (
    <div className={css.container}>
      <PageHeaderText>Requests</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput} key={clearedFormKey}>
          <FormControl fullWidth style={{ width: '150px' }}>
            <InputLabel id="select-label">Request Type</InputLabel>
            <Select
              labelId="select-label_rq_type"
              id="request-type-filter"
              value={search.type}
              label="Request Type"
              onChange={(e) => {
                setSearchParam('type', e.target.value)
              }}
            >
              {Object.values(RequestTypeGroups).map((group) => {
                const groupComponents = [
                  <ListSubheader className={css.requestTypeGroup} key={group.label}>
                    {group.label}
                  </ListSubheader>
                ]
                group.options.forEach((option) => {
                  groupComponents.push(
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  )
                })
                return groupComponents
              })}
            </Select>
          </FormControl>
          <TextField
            id="request-id-filter"
            style={{ marginLeft: '15px' }}
            label={'Request Id'}
            variant="outlined"
            value={search.request_id}
            onChange={(e) => {
              setSearchParam('request_id', e.target.value)
            }}
          />
          <TextField
            id="company-id-filter"
            style={{ marginLeft: '15px' }}
            label={'Company Id'}
            variant="outlined"
            value={search.company_id}
            onChange={(e) => {
              setSearchParam('company_id', e.target.value)
            }}
          />
          <TextField
            id="company-name-filter"
            style={{ marginLeft: '15px' }}
            label={'Company Name'}
            variant="outlined"
            value={search.company_name}
            onChange={(e) => {
              setSearchParam('company_name', e.target.value)
            }}
          />
          <TextField
            id="blog-id-filter"
            style={{ marginLeft: '15px' }}
            label={'Blog Id'}
            variant="outlined"
            value={search.blog_id}
            onChange={(e) => {
              setSearchParam('blog_id', e.target.value)
            }}
          />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setDefaultSearch()
            }}
          >
            Clear search
          </Button>
        </div>
        <div className={css.search_cpp}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Companies per page</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={limit}
              label="Companies per page"
              onChange={(e) => {
                setLimit(Number(e.target.value))
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TableRequests
        data={data}
        setToView={(id) => {
          setToView(data.find((d) => d.id === id))
        }}
      />
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={(e, v) => {
            setPage(v)
          }}
        />
      </Box>
      <Dialog
        fullWidth
        open={!!toView}
        handleClose={() => {
          setToView(undefined)
        }}
        content={<Content data={toView} />}
        title={`Request Item: ${toView?.created_at.toString()}`}
        handleOk={() => {
          setToView(undefined)
        }}
        classes={{ paper: css.dialog_window }}
      />
    </div>
  )
}

const Content: React.FC<{ data?: Request<any> }> = (params) => {
  return (
    <Stack className={css.requests_style}>
      <Typography variant="h5">Request</Typography>
      <div className={css.code}>
        <JSONView src={params.data?.request ?? {}} />
      </div>
      <Typography variant="h5" marginTop={2}>
        Contents
      </Typography>
      <code className={css.code}>
        <JSONView src={params.data?.content ?? {}} />
      </code>
    </Stack>
  )
}
export default Requests
