import React from 'react'
import { Link } from 'react-router-dom'
import { type CSManagerContent, EventType, ROUTES } from '../../../types'
import type { ColumnDef } from '@tanstack/react-table'
import css from '../CSDashboard.module.css'

const ContentHeaders = {
  [EventType.POST_GENERATED]: 'Generated',
  [EventType.POST_UPDATED]: 'Updated',
  [EventType.POST_REVIEWED]: 'Reviewed'
}

const contentColumns = Object.values(EventType)
  .filter((contentKey) => !!ContentHeaders[contentKey])
  .map((contentKey) => ({
    accessorKey: `content.${contentKey}`,
    header: ContentHeaders[contentKey]
  }))

const columns: Array<ColumnDef<CSManagerContent>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: (cell) => (
      <Link to={ROUTES.USERS + '/' + cell.row.original.id} target={'blank'} rel={'noopener noreferrer'} className={css.userLink}>
        {cell.getValue() as string}
      </Link>
    )
  },
  ...contentColumns
]

export default columns
