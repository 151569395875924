import { useContext, useMemo, useState } from 'react'
import { type FormikHelpers } from 'formik'

import { type Template } from '../../types'
import { useUpdateTemplate } from '../../api/template'
import MessageContext from '../../contexts/MessageContext'
import { type TemplateFieldGroup, defaultFieldGroup, fieldGroupsByTab } from './fieldGroups'

type UseTemplate = {
  data?: Template
  fields: string[]
  selectedTab: number
  groupedFields: GroupedFields[]
  tabNames: string[]
  onSubmit: (values: Template, helpers: FormikHelpers<Template>) => void
  switchTab: (newTabIdx: number) => void
}

type UseTemplateProps = {
  data: Template
  filter?: string
}

type GroupedFields = {
  group: TemplateFieldGroup
  fields: string[]
}

const generalTabPrefixes = ['company', 'existed', 'image', 'knowledge', 'rank']

const useTemplate = ({ data, filter }: UseTemplateProps): UseTemplate => {
  const addMessage = useContext(MessageContext)
  const updateTemplate = useUpdateTemplate()
  const [selectedTab, setSelectedTab] = useState(0)

  const fields = useMemo<string[]>(() => {
    return data?.params.common ? Object.keys(data.params.common).sort() : []
  }, [data])

  // map each field to own tab
  const tabNamesMap = useMemo<Record<string, string>>(() => {
    const tabMap: Record<string, string> = {}

    fields.forEach((key) => {
      const tabName = key.split('_')[0]

      const shouldBeInGeneralTab = generalTabPrefixes.includes(tabName)

      shouldBeInGeneralTab ? (tabMap[tabName] = 'general') : (tabMap[tabName] = tabName)
    })

    return tabMap
  }, [fields])

  // array of unique tab names
  const tabNames = useMemo<string[]>(() => {
    return Array.from(new Set(fields.map((key) => tabNamesMap[key.split('_')[0]])))
  }, [fields, tabNamesMap])

  // filter fields to display based on active tab & search term
  const filteredFields = useMemo<string[]>(() => {
    const selectedTabName = tabNames[selectedTab]
    const fieldsForTab = fields.filter((key) => tabNamesMap[key.split('_')[0]] === selectedTabName)

    if (filter) {
      return fieldsForTab.filter((key) => key.toLowerCase().includes(filter.toLowerCase() || ''))
    }

    return fieldsForTab
  }, [fields, selectedTab, tabNames, tabNamesMap, filter])

  // get field groups for active tab
  const fieldGroups = useMemo<TemplateFieldGroup[]>(
    () =>
      [...(fieldGroupsByTab[tabNames[selectedTab]] || []), defaultFieldGroup]
        .reverse()
        .map((group, groupIndex) => ({ ...group, color: groupIndex ? '#9575cd' : '#bdbdbd' }))
        .reverse(),
    [tabNames, selectedTab]
  )

  // grouping fields
  let fieldsCounter = [...filteredFields]
  const groupedFields = useMemo<GroupedFields[]>((): GroupedFields[] => {
    return fieldGroups.map((fGroup: TemplateFieldGroup): GroupedFields => {
      const groupFields: string[] = []
      fieldsCounter = fieldsCounter.filter((field) => {
        if (fGroup.test(field)) {
          groupFields.push(field)
          return false
        }
        return true
      })

      return { group: fGroup, fields: groupFields }
    })
  }, [filteredFields, fieldGroups])

  const onSubmit = (values: Template, helpers: FormikHelpers<Template>): void => {
    helpers.setSubmitting(true)
    updateTemplate
      .mutateAsync(values)
      .catch((err) => {
        addMessage({
          id: Date.now(),
          message: err.response?.data || err.message
        })
      })
      .finally(() => {
        helpers.setSubmitting(false)
      })
  }

  return {
    data,
    fields: filteredFields,
    groupedFields,
    selectedTab,
    tabNames,
    onSubmit,
    switchTab: setSelectedTab
  }
}

export default useTemplate
